// import Swiper JS
import Swiper from 'swiper';
import { Pagination, Autoplay, Keyboard } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';

document.addEventListener('DOMContentLoaded', function () {
    new Swiper('.supplier-strip__mobile', {
        modules: [Pagination, Autoplay, Keyboard],
        loop: true,
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
            el: '.supplier-swiper-pagination',
            clickable: true,
        },
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        keyboard: {
            enabled: true,
        },
        breakpoints: {
            420: {
                slidesPerView: 2,
            }
        }
    });
});